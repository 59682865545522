.loader {
    color: #ff8e0d;
    font-size: 45px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    transform: translateZ(0);
    animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
  }
  
  @keyframes mltShdSpin {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, 
      -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
       -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
       -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
       -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
       -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  @keyframes round {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
   

.active{
    color: #ff8e0d;
}


.upDownAnimation{
  animation: upDownAnimation linear infinite 6s   ;
}
.upDownAnimation1{
  animation: upDownAnimation linear infinite 7s   ;
}

@keyframes upDownAnimation {
    0%, 100%{
      transform: translateY(-100px);
    }
    50%{
      transform: translateY(100px);
    }
}


.fadeColor{
background: linear-gradient(0deg, rgba(67,67,67,0) 0%, rgba(255,253,253,0.9977240896358543) 95%);
}
.fadeColor1{
  background: rgb(255,253,253);
  background: linear-gradient(0deg, rgba(255,253,253,0.9977240896358543) 0%, rgba(67,67,67,0) 100%);
}


@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 15s linear infinite;
}


.gradientButton{
  background: linear-gradient(82deg, rgba(253, 128, 65, 0.998) 0%, rgba(255, 87, 87, 0.86) 100%);
}
.animateButton {
  position: absolute;
  background-color: white;
  width: 50px;
  height: 150px;
  animation: moveElement linear infinite 2s;
  filter: blur(10px);
  transform: rotate(45deg);
}

@keyframes moveElement {
  0%{
   transform: translateX(0%);
  }
  100%{
  transform: translateX(1200%);
  }
}



.infinite-animation{
  text-wrap: nowrap;
  color: white;
  animation: scroll-text linear infinite 20s   ;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-130%);
  }
}


.templeBg{
  background-image: url("./Assets/templebg2.jpg");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;

}



.templeBg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 14%; /* Semi-transparent black overlay */
}


@media (max-width: 768px) {
  .templeBg::before {
    display: none; /* Hide the pseudo-element on screens larger than 768px */
  }
}


.imageBg{
  background-image: url("./Assets/homepageIcons/downColorBg.jpg");
  /* width: 100%; */
  /* background-size: cover; */
  /* background-repeat: no-repeat;
  box-sizing: border-box; */

}



@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 12s linear infinite;
}
@keyframes spin2 {
  to {
      transform: rotate(-360deg);
  }
}

.animate-spin2 {
  animation: spin2 12s linear infinite;
  
}