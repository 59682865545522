.flag {
    background: linear-gradient(to right, red, #fa6921);
    margin-right: 50px;
    position: relative;
  
    /* styles for demo */
    padding: 20px;
    color: #fff;
  }
  
  /* pseudoelement to simulate triangles */
  .flag:before,
  .flag:after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 10px;
    height: 50%;
    background-image: linear-gradient(to left top, transparent 50%, #fa6921 50%);
  }
  
  /* Flip triangle */
  .flag:after {
    top: 50%;
    transform: scaleY(-1);
  }