.goog-logo-link{
    display:none !important;
   }
 /* .goog-te-gadget{
  color:transparent!important;
  } */

  .goog-te-banner-frame{
    display:none !important;
    }

    body {
        top: 0px !important;
    }
    body > .skiptranslate {
        display: none !important;
    }

    .goog-logo-link {
        display:none !important;
     }
     
     .goog-te-gadget {
        color: transparent !important;
     }
     
     .goog-te-gadget .goog-te-combo {
        color: rgb(18, 18, 18) !important;
     }
     
     div.goog-te-gadget {
        color: transparent !important;
      }
     
 .VIpgJd-ZVi9od-l4eHX-hSRGPd{
    display: none;
 }
.goog-te-combo{
    border: 1px solid rgb(140, 140, 140);
    padding: 4px;
    border-radius: 5px;
    max-width: 65px;
    max-height: 70px;
    padding: 7px 5px;
    color: rgb(168, 168, 168);
    background-color: rgb(255, 152, 63);
    
}

.goog-te-spinner {
    display: none !important;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe{
    display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf .VIpgJd-ZVi9od-aZ2wEe-OiiCO{
    display: none !important;

}